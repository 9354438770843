<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'info'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isUpdate, 'form-primary': isCreate}"
      >
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
      </v-card-title>
      <v-card-text>
        <h2 v-if="isDelete && identifier" class="my-3">
          {{ $('delete_msg', [identifier.name]) }}
        </h2>

        <v-form
          v-if="!isDelete"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                class="mt-2"
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.name"
                  v-max-length="200"
                  :label="$('name')"
                  :rules="rules.name"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'IdentifiersDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  setup() {
    return {
    }
  },
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    identifier: null,
    form: {
      name: '',
    },

  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },

    rules() {
      const rules = {};
      rules.name = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.name`)]),
      ];

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            const identifiers = await axios.get(`/identifiers/${val.id}`);
            this.form = identifiers.data.data;
            this.identifier = identifiers.data.data
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          // await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  async mounted() {

  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.isDelete) {
          await axios.delete(`/identifiers/delete/${this.data.id}`);
          this.dialogModal(false)
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
        }
        if (this.$refs.form.validate() === false) {
          this.$emit('update:table')
        }
        if (this.isCreate) {
          if (this.$refs.form.validate() === true) {
            await axios.post('/identifiers/create', this.form);
            this.dialogModal(false)
            await toast.dispatch('success', this.$t('form.success.create'))
          }
        } else if (this.isUpdate) {
          await axios.post(`/identifiers/edit/${this.data.id}`, this.form);
          this.dialogModal(false)
          await toast.dispatch('success', this.$t('form.success.update'))
        }
        this.$emit('update:table')

        // this.dialogModal(false)
      } catch (error) {
        // await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        name: '',
        address: '',
        active: '',
        funerals: '',
      };
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },

  printCredentials(payload) {
    const { password, name } = payload;
    const qr = new this.Qrious({
      level: 'H',
      size: 100,
      value: `${name}\n${password}\n`,
    });
    const winHtml = `<html lang='ar' dir="rtl">
  <style>
    @page {
    size: 89mm 61mm;
    margin: 5mm;
  }
  @media print {
    html, body {
      width: 79mm;
      height: 50mm;
    }
  }
  </style>
  <body>
    <b>اسم المعرف :</b>
    <span>${name}</span>
    <br>
    <b>كلمة المرور :</b>
    <span>${password}</span>
    <br>
    <b>يفضل تغيير كلمة المرور بعد تسجيل الدخول</b>
    <br>
    <div style="text-align: center; padding-top: 10px">
    <img src="${qr.toDataURL('image/jpeg')}" alt="qr" width="100" height="100" />
</div>
  </body>
</html>`;

    const winUrl = URL.createObjectURL(
      new Blob([winHtml], { type: 'text/html;charset=utf-8' }),
    );

    const win = window.open(
      winUrl,
      'win',
      'width=800,height=400,screenX=0,screenY=0',
    );
    win.focus();
    win.print();
    win.onafterprint = () => {
      win.close()
    };
  },

}
</script>

<style scoped>

</style>
