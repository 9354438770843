<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-can="'create_identifiers'"
        color="primary"
        dark
        @click="dialogModal(true, 'create')"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        {{ $('create') }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="identifiers"
      :options.sync="options"
      :server-items-length="identifiersTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>

      <template v-slot:item.user.name="{ item }">
        <v-chip
          v-if="item.user"
          label
          color="black"
          small
          dark
        >
          {{ item.user.name }}
        </v-chip>
      </template>

      <template v-slot:item.created_at="{item}">
        {{ item.created_at | format_date }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <menu-item v-can="'update_identifiers'" :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item.id)"></menu-item>
              <menu-item v-can="'delete_identifiers'" :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item.id)"></menu-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <identifiers-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></identifiers-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import IdentifiersDialog from '@/views/pages/Identifiers/dialog.vue'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import MenuItem from '@/components/menu/menuItem.vue';

export default {
  name: 'Checkpoints',
  components: { DatatableHeader, IdentifiersDialog, MenuItem },
  data: () => ({
    translationKey: 'pages.Identifiers',
    filters: false,
    identifiers: [],
    identifiersTotal: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'name',
        align: 'center',
        sortable: false,
        value: 'name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'admin',
        value: 'user.name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'created_at',
        value: 'created_at',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },

      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  setup() {
    return {

    }
  },
  mounted() {
    this.options = getOptions(this.$route, this.options);
    this.fetch();
  },

  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        filters,
      })
      const response = await axios.get(`/identifiers/dt?${query}`);
      this.identifiers = response.data.data.data
      this.identifiersTotal = response.data.data.total
      this.loading = false
    },
    async toggleActive(item) {
      item.active = !item.active;
      await axios.post(`/identifiers/edit/${item.id}`, item);
      await this.fetch();
    },

    async toggleFunerals(item) {
      item.funerals = !item.funerals;
      await axios.post(`/identifiers/edit/${item.id}`, item);
      await this.fetch();
    },

    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    $(key) {
      return this.$t(`${this.translationKey}.${key}`);
    },
  },

}
</script>
